import React from "react"
import { graphql } from "gatsby"

import {
  Seo,
  Layout,
  HeaderTextOnly,
  RichText,
  PlansSection,
  SectionTextWithImage,
  Accordions,
  ButtonList,
  Button,
} from "../components"
import { Plan } from "../types"
import { smoothScrollToElementWithId, handleSectionBackground } from "../utils"

type PricingPage = {
  location: {
    href: string
  }
  data: {
    contentfulPageGeneric: {
      id: string
      heading: string
      header: {
        raw: string
      }
      pageSections: any
      seo: {
        description: {
          description: string
        }
        titleTemplate: {
          extension: string
        }
        title: string
        image: {
          fixed: {
            src: string
          }
        }
      }
    }
    contentfulSubscriptions: {
      contentful_id: string
      permissions: {
        name: string
        id: string
      }
      plans: Array<Plan>
    }
  }
}

const Pricing = ({ data, location }: PricingPage) => {
  const page = data.contentfulPageGeneric
  const plans = data.contentfulSubscriptions.plans
  const permissions = data.contentfulSubscriptions.permissions

  return (
    <Layout>
      <Seo
        title={page?.seo?.title}
        description={page?.seo?.description?.description}
        titleTemplate={page?.seo?.titleTemplate?.extension}
        article={false}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <HeaderTextOnly heading={page?.heading} alignment="center">
        <RichText content={page.header} variant="header-text-only" />
        <div className="plan-buttons">
          <h2 className="plan-buttons-heading">Our plans</h2>
          <ButtonList variant="vertical-merged">
            {plans.map((plan) => (
              <Button
                key={plan.contentful_id}
                style="secondary"
                variant="full-width"
                onClick={() =>
                  smoothScrollToElementWithId(`plan-${plan.contentful_id}`)
                }
              >
                {plan.name}
              </Button>
            ))}
          </ButtonList>
        </div>
      </HeaderTextOnly>
      <PlansSection plans={plans} allPermissions={permissions} />
      {page?.pageSections?.map((section: any, index: number) => {
        if (section.__typename === "ContentfulPageSectionTextWithImage") {
          return (
            <SectionTextWithImage
              key={section.contentful_id}
              contentful_id={section.contentful_id}
              backgroundColour={handleSectionBackground(
                section.backgroundColour,
                index % 2 === 0 ? "grey-lightest" : "white"
              )}
              heading={section.heading}
              content={section.content}
              image={section?.media?.media?.gatsbyImageData}
              imageAlt={section?.media?.media?.title}
              showImageFirst={section.showImageFirst}
              imageBlobBackground={section.imageBlobBackground}
              showSignUpButton={section.showSignUpButton}
              scheduleDemoButton={section.scheduleDemoButton}
              file={section?.media?.media?.file?.url}
              youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
            />
          )
        } else if (section.__typename === "ContentfulPageSectionAccordions") {
          return (
            <SectionTextWithImage
              key={section.contentful_id}
              backgroundColour={handleSectionBackground(
                section.backgroundColour,
                index % 2 === 0 ? "grey-lightest" : "white"
              )}
              heading={section.heading}
              contentful_id={section.contentful_id}
              content={section.content}
              image={section?.media?.media?.gatsbyImageData}
              imageAlt={section?.media?.media?.title}
              showImageFirst={section.showImageFirst}
              imageBlobBackground={section.imageBlobBackground}
              showSignUpButton={section.showSignUpButton}
              scheduleDemoButton={section.scheduleDemoButton}
              file={section?.media?.media?.file?.url}
              youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
              variant="accordions"
            >
              <Accordions accordions={section.accordions} />
            </SectionTextWithImage>
          )
        }
      })}
    </Layout>
  )
}

export default Pricing

export const pageQuery = graphql`
  query getPricingPageData {
    contentfulPageGeneric(name: { eq: "Pricing" }) {
      id
      heading
      header {
        raw
      }
      pageSections {
        ... on ContentfulPageSectionAccordions {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          accordions {
            heading
            body {
              raw
            }
            contentful_id
          }
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
        ... on ContentfulPageSectionTextWithImage {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
      }
      seo {
        description {
          description
        }
        titleTemplate {
          extension
        }
        title
        image {
          fixed {
            src
          }
        }
      }
    }
    contentfulSubscriptions(name: { eq: "Subscriptions master" }) {
      contentful_id
      permissions {
        name
        contentful_id
      }
      plans {
        contentful_id
        permissions {
          name
          contentful_id
        }
        cost
        name
        mostPopular
        description
      }
    }
  }
`
